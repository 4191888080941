import { Button } from '@fluentui/react-northstar'
import { getSchema, Schema } from 'helpers/capture'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { RPPGData } from 'helpers/rppg'
import './Results.scss'

export interface ResultData {
  rppgData: RPPGData
  isAllDataCalculated: boolean
}

export const Results = () => {
  const history = useHistory()
  const location = useLocation()

  const [data, setData] = useState<ResultData>()
  const [schema, setSchema] = useState<Schema[]>()

  useEffect(() => {
    const data = location.state as ResultData
    console.log(data)
    if (!data) {
      history.push('/capture')
      return
    }

    setSchema(getSchema())
    const newData: any = Object.assign({}, data)
    if(!newData.rppgData.measurementData.bloodPressureStatus) {
      newData.rppgData.measurementData.bloodPressureStatus = 'NORMAL'
    }
    if(!newData.rppgData.measurementData.stressStatus) {
      newData.rppgData.measurementData.stressStatus = 'NORMAL'
    }

    setData(newData)
  }, [history, location.state])

  const onBackClickButtonHandler = () => {
    history.push('/capture')
  }

  const renderBPRange = (status: any) => {
    if(status === 'NORMAL') {
      return (
        <div className='bp-stats'>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>Less than 120</div>
            <div className='bp-stats'>Less than 80</div>
          </div>
        </div>
      )
    }else if(status === 'LOW') {
      return (
        <div>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>Less than 90</div>
            <div className='bp-stats'>Less than 60</div>
          </div>
        </div>
      )
    } else if(status === 'ELEVATED') {
      return (
        <div>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>120 - 129</div>
            <div className='bp-stats'>Less than 80</div>
          </div>
        </div>
      )
    } else if(status?.toString().toLowerCase().includes('stage 1')) {
      return (
        <div>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>130 - 139</div>
            <div className='bp-stats'>80 - 89</div>
          </div>
        </div>
      )
    }  else if(status?.toString().toLowerCase().includes('stage 2')) {
      return (
        <div>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>140 or higher</div>
            <div className='bp-stats'>90 or higher</div>
          </div>
        </div>
      )
    } else if(status?.toString().toLowerCase().includes('hypertensive') || status?.toString().toLowerCase().includes('crisis')) {
      return (
        <div>
          <div className='item' style={{border: 0}}>
            <div className='bp-stats'>Systolic mm Hg</div>
            <div className='bp-stats'>Diastolic mm Hg</div>
          </div>
          <div className='item'>
            <div className='bp-stats'>Higher than 180</div>
            <div className='bp-stats'>Higher than 120</div>
          </div>
        </div>
      )
    } else {
      return(
        <div></div>
      )
    }
  }

  return (
    <div className="results-container">
      <img src={require('assets/images/home/skeleton-1.png').default} alt='skeleton' width={250} />

      <div className="msg-block">
        <p className="title">Scan complete</p>
        <p className="description">
          We do not claim to diagnose, mitigate, prevent or treat any disease,
          any disorders, symptoms or abnormal physical state.
        </p>
      </div>
      <br />

      {data && schema && (
        <div className="results">

          {schema.map(item => (
            <div>
              {(get(data.rppgData, item.key, 0) !== 0 && get(data.rppgData, item.key, 0))  && <div className="item" key={item.key}>
                <div className="title">
                  <div className="icon">
                    <img src={require(`assets/images/${item.iconResult}`).default} alt="icon-result" />
                  </div>
                  <div className="name">
                    {item.name}
                  </div>
                </div>

                  {get(data.rppgData, item.key, 0) ? (
                    <div className="value">
                      {get(data.rppgData, item.key, 0)}
                      <span className="sign">{item.sign}</span>
                    </div>
                  ) : (
                    <div className="no-value">
                      <img src={require('assets/images/note-icon.svg').default} alt="icon-note" />
                    </div>
                  )}
                </div>
              }
              <div style={{width: '100%', display: 'block'}}>
                {
                  item.name === 'Blood Pressure' && <div>{renderBPRange(get(data.rppgData, item.key, 0))}</div>
                }
                </div>
            </div>
          ))}

          <div style={{ width: '100%', display: 'block' }}>
            HRV
            <div className='bp-stats'>
              <div className='item' style={{ border: 0 }}>
                <div className='bp-stats'>IBI</div>
                <div className='bp-stats'>RMSSD</div>
                <div className='bp-stats'>SDNN</div>
              </div>
              <div className='item'>
                <div className='bp-stats'>{data.rppgData.hrvMetrics.ibi}</div>
                <div className='bp-stats'>{data.rppgData.hrvMetrics.rmssd}</div>
                <div className='bp-stats'>{data.rppgData.hrvMetrics.sdnn}</div>
              </div>
            </div>
          </div>


          {!data.isAllDataCalculated && (
            <div className="notification">
              <img src={require('assets/images/note-icon.svg').default} alt="icon-note" />
              One or more vitals were unable to be calculated due to bad lighting conditions, please try again later
            </div>
          )}
        </div>
      )}
      <br />
      <Button
        primary
        style={{ background: '#2276f4' }}
        onClick={onBackClickButtonHandler} 
        content="Take another test" />
    </div>

  )
}
